import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  error: boolean = false;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isUserLogged()) {
      this.router.navigate(['/amonestaciones/profesor']);
      console.log("Login Component. ngOnInit: Usuario ya loggeado. Redirigido a: amonestaciones")
    }
  }

  login() {
    if ((this.username != '') && (this.password != '')) {
      this.authenticationService.login(this.username, this.password).subscribe(
          data => {
            this.username = '';
            this.password = '';    
            this.router.navigate(['/amonestaciones/profesor']);
            console.log('Login Component. OK');
          },
          err => {
            this.error = true;
            console.log('Login Component. Error: ' + JSON.stringify(err));
          }
      );
    } else {
      this.error = true;
    }
  }


}
