import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    //console.log('HTTP_INTERCEPTOR');

    let currentUser = this.authService.getCurrentUser();
    // No se usa: let tokenExpiration = this.authService.getTokenExpiration();
    //let tokenExpiration = JSON.parse(localStorage.getItem('tokenExpiration'));

    //console.log('HTTP_INTERCEPTOR: token: ' + currentUser.token + ' - tokenExpiration: ' + tokenExpiration);
    if (this.authService.isTokenExpired()) {
      this.authService.logout();
      this.router.navigate(["/login"]);
      return next.handle(request);;
    } else if (currentUser && currentUser.token) {
      let requestWithToken = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${currentUser.token}`,
            'Accept': 'application/json'
          }
      });
      return next.handle(requestWithToken);
    } else {
      return next.handle(request);
    }
  }
 
}
