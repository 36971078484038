import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { User } from '../interfaces/data-model';
import { DatabaseApiService } from './database-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //currentUser: User;
  tokenSubscription = new Subscription();
  tokenExpirationTime = 3600000; // Una hora: 1000 ms * 60 seg * 60 min


  constructor(private router: Router, private databaseApi: DatabaseApiService) {
/*
    this.currentUser = {
      username: '',
      email: '',
      password: '',
      nombre: '',
      apellido1: '',
      apellido2: '',
      dni: '',
      telefono1: '',
      telefono2: '',
      departamento: '',
      cargos: '',
      role: '',
      foto: '',
    };    
*/
  }

  /*
  console.log("AppComponent. ngOnInit");
  if (this.authService.isTokenExpired()) {
    this.authService.logout().subscribe(
      resp => {
        console.log('AppComponent. Logout OK. ' + resp);
        this.router.navigate(['/login']);
      },
      error => {
        console.log('AppComponent. Logout Error: ' + error);
        this.router.navigate(['/login']);
      }
    );

  }
*/

  login(username: string, password: string): Observable<User> {
    return this.databaseApi.login(username, password)
      .pipe(
        map(resp => {
          // login successful if there's a jwt token in the response.
          if (resp.user && resp.token) {
            // store user details and jwt token in local storage to keep user
            // logged in between page refreshes.
            resp.user.token = resp.token;
            localStorage.setItem('currentUser', JSON.stringify(resp.user));
            localStorage.setItem('tokenExpiration', JSON.stringify(Date.now() + this.tokenExpirationTime))
            console.log('AuthenticationService: Ok. Logged in. User: ', resp.user);
            var today = new Date();
            //console.log(today.getHours() + ":" + today.getMinutes() + ' - TOKEN CREATED!!');
            // Creamos un observable para que nos avise cuando expire el token
            this.tokenSubscription.unsubscribe();
            this.tokenSubscription = of(null).pipe(delay(this.tokenExpirationTime)).subscribe((expired) => {
              var today = new Date();
              //console.log(today.getHours() + ":" + today.getMinutes() + ' - TOKEN EXPIRED!!');
              this.logout();
              this.router.navigate(["/login"]);
            });
          } else {
            console.log('AuthenticationService: Error: ', resp.user);
          }
          return resp;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenExpiration');
    this.tokenSubscription.unsubscribe();
    return this.databaseApi.logout()
      .pipe(
        map(resp => {
          return true;
        })
      );
  }

  getCurrentUser(): User {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

  getTokenExpiration(): Number {
    let tokenExpiration = JSON.parse(localStorage.getItem('tokenExpiration'));
    return tokenExpiration;
  }

  isUserLogged(): Boolean {
    //console.log('AuthenticationService.isUserLogged: ', !(this.currentUser.id == null))
    let currentUser = this.getCurrentUser();
    if (currentUser && currentUser.token) {
      return true;
    } else {
      return false;
    }    
  }

  isTokenExpired(): Boolean {
    const currentDate: Number = Date.now();
    if (currentDate > this.getTokenExpiration()) {
      return true;
    } else {
      return false;
    }
  }

  isUserAdmin(): Boolean {
    let currentUser = this.getCurrentUser();
    if (currentUser && currentUser.role == "USER_ADMIN") {
      return true;
    } else {
      return false;
    }
  }

  isUserTutor(): Boolean {
    let currentUser = this.getCurrentUser();
    if (currentUser && currentUser.role == "USER_TUTOR") {
      return true;
    } else {
      return false;
    }
  }

  getUsername(): string {
    let currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.username;
    } else {
      return null;
    }
  }

  getUserEmail(): string {
    let currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.email;
    } else {
      return null;
    }
  }

  getUserFullName(): string {
    let currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.apellido1 + " " 
            + (currentUser.apellido2 ? currentUser.apellido2 : "") + ", " 
            + currentUser.nombre;
    } else {
      return null;
    }
  }
  
  getUserId(): number {
    let currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.id;
    } else {
      return null;
    }
  }
  
}
