import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'pagenotfound',
    component: NotfoundComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      // Módulo Usuarios
      {
        path: 'usuarios',
        loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule)
      },
      // Módulo Alumnos
      {
        path: 'alumnos',
        loadChildren: () => import('./alumnos/alumnos.module').then(m => m.AlumnosModule)
      },
      // Módulo Grupos
      {
        path: 'grupos',
        loadChildren: () => import('./grupos/grupos.module').then(m => m.GruposModule)
      },
      // Módulo Amonestaciones
      {
        path: 'amonestaciones',
        loadChildren: () => import('./amonestaciones/amonestaciones.module').then(m => m.AmonestacionesModule)
      },
      // Módulo Actuaciones
      {
        path: 'actuaciones',
        loadChildren: () => import('./actuaciones/actuaciones.module').then(m => m.ActuacionesModule)
      },
      // Módulo Ajustes
      {
        path: 'ajustes',
        loadChildren: () => import('./ajustes/ajustes.module').then(m => m.AjustesModule)
      },
    ]
  },
  
  // Ruta vacía
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
  // Ruta por defecto
  {
    path: '**', redirectTo: '/pagenotfound', pathMatch: 'full'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
    preloadingStrategy: PreloadAllModules,
    useHash: true,
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'top'
}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
