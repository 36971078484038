import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Angular Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from './services/authentication.service';
import { DatabaseApiService } from './services/database-api.service';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';

import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotfoundComponent,
    NavbarTopComponent,
    SideMenuComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
  ],
  providers: [
    AuthenticationService,
    DatabaseApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
