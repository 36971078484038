import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, Subscription } from 'rxjs';

import { User, UserName, Alumno, Grupo, Amonestacion, TiposIncidencias, Actuacion, TiposActuaciones, ResponseMostrarActuacion } from '../interfaces/data-model';
import { shareReplay, catchError, map } from 'rxjs/operators';

/* NOTA: 
    Para poder hacer las pruebas en local hay que instalar el siguiente Add-on en Firefox:
    https://addons.mozilla.org/es/firefox/addon/cors-everywhere/
*/

@Injectable({
  providedIn: 'root'
})
export class DatabaseApiService {
  //  API_URL = 'http://172.19.188.156:7000/api/';    // Servidor pruebas
  //  API_URL = 'http://aulainfo.com:8000/api/';      // Servidor Aulainfo en pruebas
  API_URL = 'https://gdc.ieslaencanta.com/srv/api/';  // Servidor IES La Encanta en Producción
  //  API_URL = 'http://84.232.101.226:7000/api/';    // Servidor pruebas desde exterior

  teachers: Observable<User[]> = null;
  students: Observable<Alumno[]> = null;
  admonitions: Observable<Amonestacion[]> = null;
  studentsInfo: Observable<Alumno>[] = [];

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string): Observable<any> {
    console.log("API: " + this.API_URL + 'login');
    return this.http.post<any>(this.API_URL + 'login', { username, password });
  }

  logout() {
    console.log("API: " + this.API_URL + 'logout');
    this.teachers = null;     // Invalida la caché
    this.students = null;     // Invalida la caché
    this.admonitions = null;  // Invalida la caché
    return this.http.post<any>(this.API_URL + 'logout', null);
  }


  // PROFESORES
  getUsersList(): Observable<User[]> {
    if (this.teachers == null) {
      console.log("API: " + this.API_URL + 'profesores');
      this.teachers = this.http.get<User[]>(this.API_URL + 'profesores')
        .pipe(shareReplay(1),
          catchError(err => {
            console.log('Database-API. Error: ' + JSON.stringify(err));
            this.teachers = null;
            return this.teachers;
          })
        );
    } else {
      console.log("API: " + this.API_URL + 'profesores' + ' (CACHE)');
    }
    return this.teachers;
  }

  
  getUsersNameList(): Observable<UserName[]> {
    console.log("API: " + this.API_URL + 'profesores_names');
    return this.http.get<UserName[]>(this.API_URL + 'profesores_names');
  }


  getUserById(id: number): Observable<User> {
    console.log("API: " + this.API_URL + 'profesores/' + id);
    return this.http.get<User>(this.API_URL + 'profesores/' + id);
  }

  setUserById(user: User): Observable<User> {
    // Modifica el usuario user
    console.log("API: " + this.API_URL + 'profesores/' + user.id);
    this.teachers = null; // Invalida la caché
    return this.http.put<User>(this.API_URL + 'profesores/' + user.id, user);
  }

  addUser(user: User): Observable<User> {
    // Añade un usuario nuevo
    console.log("API: " + this.API_URL + 'profesores');
    this.teachers = null; // Invalida la caché
    return this.http.post<User>(this.API_URL + 'profesores', user);
  }

  deleteUser(id: number): Observable<{}> {
    // Borra un usuario profesor
    console.log("API: " + this.API_URL + 'profesores/' + id);
    this.teachers = null; // Invalida la caché
    return this.http.delete(this.API_URL + 'profesores/' + id);
  }

  // IMPORTACIONES
  importUsersList(file: File): Observable<any> {
    // Importa una lista de usuarios profesores
    console.log("API: " + this.API_URL + 'profesores/importar');
    this.teachers = null; // Invalida la caché
    const formData: FormData = new FormData();
    formData.append('archivo', file, file.name);
    return this.http.post<any>(this.API_URL + 'profesores/importar', formData);
  }

  importGroupsList(file: File): Observable<any> {
    // Importa una lista de usuarios profesores
    console.log("API: " + this.API_URL + 'grupo/importar');
    const formData: FormData = new FormData();
    formData.append('archivo', file, file.name);
    return this.http.post<any>(this.API_URL + 'grupo/importar', formData);
  }

  importStudentsList(file: File): Observable<any> {
    // Importa una lista de usuarios profesores
    console.log("API: " + this.API_URL + 'alumnos/importar');
    this.students = null; // Invalida la caché
    this.studentsInfo = []; // Invalida la caché
    const formData: FormData = new FormData();
    formData.append('archivo', file, file.name);
    return this.http.post<any>(this.API_URL + 'alumnos/importar', formData);
  }


  // ROLES
  getRolesList(): Observable<string[]> {
    console.log("API: " + this.API_URL + 'rol');
    return this.http.get<string[]>(this.API_URL + 'rol');
  }


  // GRUPOS
  getGroupsList(): Observable<Grupo[]> {
    console.log("API: " + this.API_URL + 'grupo');
    return this.http.get<Grupo[]>(this.API_URL + 'grupo');
  }

  addGroup(group: Grupo): Observable<any> {
    // Añade un grupo nuevo
    console.log("API: " + this.API_URL + 'grupo' + ". Parámetros: Nombre: " + group.nombre + " - Tutor: " + group.tutor);
    return this.http.post<any>(this.API_URL + 'grupo', group)
  }

  getGroupById(id: number): Observable<Grupo> {
    console.log("API: " + this.API_URL + 'grupo/' + id);
    return this.http.get<Grupo>(this.API_URL + 'grupo/' + id);
  }

  setGroupById(group: Grupo): Observable<Grupo> {
    // Modifica el grupo
    console.log("API: " + this.API_URL + 'grupo/' + group.id);
    return this.http.put<Grupo>(this.API_URL + 'grupo/' + group.id, group);
  }

  deleteGroup(id: number): Observable<{}> {
    // Borra un grupo
    console.log("API: " + this.API_URL + 'grupo/' + id);
    return this.http.delete(this.API_URL + 'grupo/' + id);
  }


  // ALUMNOS
  
  getStudentsList(): Observable<Alumno[]> {
    if (this.students == null) {
      console.log("API: " + this.API_URL + 'alumnos');
      this.students = this.http.get<Alumno[]>(this.API_URL + 'alumnos')
        .pipe(shareReplay(1),
          catchError(err => {
            console.log('Database-API. Error: ' + JSON.stringify(err));
            this.students = null;
            return this.students;
          })
        );
    } else {
      console.log("API: " + this.API_URL + 'alumnos' + ' (CACHE)');
    }
    // Devolvemos el valor de caché
    return this.students;
  }

  getStudentById(id: number): Observable<Alumno> {
    if (this.studentsInfo[id] == undefined) {
      console.log("API: " + this.API_URL + 'alumnos/' + id);
      this.studentsInfo[id] = this.http.get<Alumno>(this.API_URL + 'alumnos/' + id)
        .pipe(shareReplay(1),
          catchError(err => {
            console.log('Database-API. Error: ' + JSON.stringify(err));
            this.studentsInfo[id] = null;
            return this.studentsInfo[id];
          })
        );
    } else {
      // Devolvemos el valor de caché
      console.log("API: " + this.API_URL + 'alumnos/' + id + " (CACHE)");
    }
    return this.studentsInfo[id];
  }

  addStudent(alumno: Alumno): Observable<Alumno> {
    // Añade el alumno
    console.log("API: " + this.API_URL + 'alumnos');
    this.studentsInfo[alumno.id] = null; // Invalida la caché
    this.students = null;
    return this.http.post<Alumno>(this.API_URL + 'alumnos', alumno);
  }

  setStudentById(alumno: Alumno): Observable<Alumno> {
    // Modifica el alumno
    console.log("API: " + this.API_URL + 'alumnos/' + alumno.id);
    this.studentsInfo[alumno.id] = null; // Invalida la caché
    this.students = null;
    return this.http.put<Alumno>(this.API_URL + 'alumnos/' + alumno.id, alumno);
  }

  deleteStudent(id: number): Observable<{}> {
    // Borra un alumno
    console.log("API: " + this.API_URL + 'alumnos/' + id);
    this.studentsInfo[id] = null; // Invalida la caché
    this.students = null;
    return this.http.delete(this.API_URL + 'alumnos/' + id);
  }

  // AMONESTACIONES
  addAdmonition(amonestacion: Amonestacion): Observable<Amonestacion> {
    // Añade una amonestacion
    console.log("API: " + this.API_URL + 'amonestacion');
    this.admonitions = null;    // Invalida la caché
    return this.http.post<Amonestacion>(this.API_URL + 'amonestacion', amonestacion);
  }

  getAdmonitionById(id: number): Observable<Amonestacion> {
    console.log("API: " + this.API_URL + 'amonestacion/' + id);
    return this.http.get<Amonestacion>(this.API_URL + 'amonestacion/' + id);
  }

  getAdmonitions(): Observable<Amonestacion[]> {
    if (this.admonitions == null) {
      console.log("API: " + this.API_URL + 'amonestacion');
      this.admonitions = this.http.get<Amonestacion[]>(this.API_URL + 'amonestacion')
      .pipe(shareReplay(1),
        catchError(err => {
          console.log('Database-API. Error: ' + JSON.stringify(err));
          this.admonitions = null;
          return this.admonitions;
        })
      );
    } else {
      console.log("API: " + this.API_URL + 'amonestacion' + ' (CACHE)');
    }
    // Devolvemos el valor de caché
    return this.admonitions;

  }

  getAdmonitionsByGroup(id: number): Observable<Amonestacion[]> {
    console.log("API: " + this.API_URL + 'amonestacion/grupo/' + id);
    return this.http.get<Amonestacion[]>(this.API_URL + 'amonestacion/grupo/' + id);
  }

  getAdmonitionsByTeacher(id: number): Observable<Amonestacion[]> {
    console.log("API: " + this.API_URL + 'amonestacion/profesor/' + id);
    return this.http.get<Amonestacion[]>(this.API_URL + 'amonestacion/profesor/' + id);
  }

  getAdmonitionCauses(): Observable<TiposIncidencias[]> {
    console.log("API: " + this.API_URL + 'tipo_amonestacion');
    return this.http.get<TiposIncidencias[]>(this.API_URL + 'tipo_amonestacion');
  }

  setAdmonition(amonestacion: Amonestacion): Observable<Amonestacion> {
    // Modifica una amonestacion
    console.log("API: " + this.API_URL + 'amonestacion/' + amonestacion.id);
    this.admonitions = null;    // Invalida la caché
    return this.http.put<Amonestacion>(this.API_URL + 'amonestacion/' + amonestacion.id, amonestacion);
  }

  deleteAdmonition(id: number): Observable<{}> {
    // Borra una amonestación
    console.log("API: " + this.API_URL + 'amonestacion/' + id);
    this.admonitions = null;    // Invalida la caché
    return this.http.delete(this.API_URL + 'amonestacion/' + id);
  }

  // ACTUACIONES
  getActuaciones(): Observable<Actuacion[]> {
    console.log("API: " + this.API_URL + 'actuacion');
    //return this.http.get<Actuacion[]>(this.API_URL + 'actuacion');
    return this.http.get<Actuacion[]>(this.API_URL + 'amonestacion');
    //return null;
  }

  getTiposActuaciones(): Observable<TiposActuaciones[]> {
    //return this.http.get<TiposActuaciones[]>(this.API_URL + 'tipoactuaciones');

    let tipoActuaciones: TiposActuaciones[] = [
      { "id": 1, "descripcion": "Expulsión del Centro" },
      { "id": 2, "descripcion": "Quedarse sin recreo" },
      { "id": 3, "descripcion": "Quedarse de 14:00H a 15:00h" },
      { "id": 4, "descripcion": "Otro" }
    ];

    return of(tipoActuaciones);
  }


  addActuacion(actuacion: Actuacion, amonestaciones: Array<number>): Observable<TiposActuaciones[]> {
    // Añade una actuacion
    //console.log("API: " + this.API_URL + 'actuacion');
    //return this.http.post<Actuacion>(this.API_URL + 'actuacion', actuacion);
    console.log("Nueva actuación: " + actuacion);
    console.log("Amonestaciones Ids: " + amonestaciones);
    
    let tipoActuaciones: TiposActuaciones[] = [
      { "id": 1, "descripcion": "Expulsión del Centro" },
      { "id": 2, "descripcion": "Quedarse sin recreo" },
      { "id": 3, "descripcion": "Quedarse de 14:00H a 15:00h" },
      { "id": 4, "descripcion": "Otro" }
    ];

    return of(tipoActuaciones);
  }

  getActuacionById(id: number): Observable<ResponseMostrarActuacion> {
    console.log("API: " + this.API_URL + 'actuacion/' + id);
    return this.http.get<ResponseMostrarActuacion>(this.API_URL + 'actuacion/' + id);
  }

  // Borrar:
  /*
  getActuacionByGroup(id: number): Observable<Actuacion[]> {
    console.log("API: " + this.API_URL + 'actuacion/grupo/' + id);
    return this.http.get<Actuacion[]>(this.API_URL + 'actuacion/grupo/' + id);
  }
  */

  getActuacionCauses(): Observable<TiposActuaciones[]> {
    console.log("API: " + this.API_URL + 'tipo_actuacion');
    return this.http.get<TiposActuaciones[]>(this.API_URL + 'tipo_actuacion');
  }

  // ToDo: Haría falta incluir el listado de amonestaciones antiguas y nuevas
  setActuacion(actuacion: Actuacion): Observable<Actuacion> {
    // Modifica una actuacion
    console.log("API: " + this.API_URL + 'actuacion/' + actuacion.id);
    return this.http.put<Actuacion>(this.API_URL + 'actuacion/' + actuacion.id, actuacion);
  }

  deleteActuacion(id: number): Observable<{}> {
    // Borra una actuacion
    console.log("API: " + this.API_URL + 'actuacion/' + id);
    return this.http.delete(this.API_URL + 'actuacion/' + id);
  }

}

