<!-- Top navbar -->
<nav class="navbar navbar-expand-sm navbar-dark bg-primary border-bottom">
  <div class="container-fluid">
    <a class="navbar-brand fw-bold" routerLink="/amonestaciones/profesor/">
      <img src="./assets/logoies.png" alt="Logo" width="30" height="30" class="d-inline-block align-text-bottom me-4">
      IES La Encantá
    </a>
  </div>

  <ul class="navbar-nav ms-auto mt-0">
    <li class="nav-item dropdown">
      <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block">
        <button class="btn btn-link text-white" id="dropdownCurrentUser" ngbDropdownToggle>{{ username }}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownCurrentUser">
          <div class="dropdown-item">{{ fullname }}</div>
          <div class="dropdown-item">{{ email }}</div>
          <button ngbDropdownItem (click)="logout()">Cerrar sesión <i class="fa fa-sign-out" aria-hidden="true"></i></button>
        </div>
<!--
        <span style="font-size: 1.2em; color: white;">
          <i class="fa fa-user-circle" aria-hidden="true"></i>
        </span>
-->
      </div>
    </li>
  </ul>
</nav>

<!-- Menú principal -->
<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="container-fluid">
    <span class="navbar-brand h1 align-baseline">Gestión de Centro</span>
    <button class="navbar-toggler" type="button" aria-controls="navbarMainMenu"
      [attr.aria-expanded]="!collapsedMainMenu" aria-label="Toggle navigation"
      (click)="collapsedMainMenu = !collapsedMainMenu">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [class.collapse]="collapsedMainMenu" id="navbarMainMenu">
      <ul class="navbar-nav me-auto">

        <li class="nav-item">
          <a class="nav-link text-dark" routerLink="/amonestaciones/profesor/" role="button">
            Amonestaciones
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link text-dark" routerLink="/alumnos/" role="button">
            Alumnos
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link text-dark" routerLink="/grupos/" role="button">
            Grupos
          </a>
        </li>

        <li *ngIf="authService.isUserAdmin()" class="nav-item" ngbDropdown>
          <a class="nav-link text-dark" tabindex="0" ngbDropdownToggle id="navbarDropdownConvi" role="button">
            Convivencia
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownConvi" class="dropdown-menu">
            <a ngbDropdownItem routerLink="/amonestaciones/">Amonestaciones</a>
            <!-- EN PROCESO: <a ngbDropdownItem routerLink="/actuaciones/">Actuaciones</a> -->
          </div>
        </li>

        <li *ngIf="authService.isUserAdmin()" class="nav-item" ngbDropdown>
          <a class="nav-link text-dark" tabindex="0" ngbDropdownToggle id="navbarDropdownConvi" role="button">
            Ajustes
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownConvi" class="dropdown-menu">
            <a ngbDropdownItem routerLink="/ajustes/importar-datos">Importar datos</a>
            <a ngbDropdownItem routerLink="/usuarios/">Profesores</a>
          </div>
        </li>

      </ul>
    </div>
  </div>
</nav>