import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  @Input() isSideMenuCollapsed;
  userId: number;

  isCollapsedConvi: boolean;
  isCollapsedAjustes: boolean;

  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
    this.userId = this.authService.getUserId();
  }

}
