<div class="text-center mt-4">
  <img class="mt-4" src="./assets/logoies.png" alt="" width="80" height="80">
  <h4 class="mb-4 mt-2">IES La Encantá</h4>
  <h2 class="mb-4">Gestión de Centro (GDC)</h2>

  <form #loginForm="ngForm" class="mb-4" (ngSubmit)="login()" novalidate>
    <div class="text-start mb-4 ">
      <!--<label class="" for="inputUsername">Usuario:</label>-->
      <input type="text" class="form-control" name="username" #usernameModel="ngModel" [(ngModel)]="username" id="inputUsername" placeholder="Nombre de usuario">
      <small id="usuarioHelp" class="form-text text-muted">Ejemplo: aperezg</small>
    </div>
    <div class="text-start">
      <!--<label for="inputPassword">Contraseña:</label>-->
      <input type="password" class="form-control" name="password" #passwordModel="ngModel" [(ngModel)]="password" id="inputPassword" placeholder="Contraseña">
    </div>
    <div *ngIf="error" class="text-danger">Datos incorrectos.
      <br>Revisa el usuario y contraseña</div>
    <button type="submit" class="btn btn-primary mt-3">Acceder</button>
  </form>

  <small class="footer">Versión: 0.6 (23/01/2025)</small>

</div>