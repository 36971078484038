<!-- Barra superior -->
<app-navbar-top *ngIf="this.authService.isUserLogged()"></app-navbar-top>

<!-- <div class="d-flex" id="wrapper">  -->
  <!-- Menú lateral -->
<!--
  <app-side-menu *ngIf="this.authService.isUserLogged()"></app-side-menu>
-->  
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="container-fluid mt-2">
      <router-outlet></router-outlet>
    </div>
  </div>
<!-- </div> -->