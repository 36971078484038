<!-- Sidebar -->
<!--
<div class="bg-light border-right" id="sidebar-wrapper" [ngbCollapse]="this.isSideMenuCollapsed">
  -->
<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading fw-bold">Gestión de centro</div>
  <div class="list-group list-group-flush">

    <!-- Amonestaciones -->
    <div class="list-group-item list-group-item-action bg-light border-top ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" routerLink="/amonestaciones/profesor/">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-id-card" aria-hidden="true"></i></span> Amonestaciones
      </button>
    </div>

    <div class="list-group-item list-group-item-action bg-light ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" routerLink="/alumnos/">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-user" aria-hidden="true"></i></span> Alumnos
      </button>
    </div>

    <div class="list-group-item list-group-item-action bg-light ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" routerLink="/grupos/">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-users" aria-hidden="true"></i></span> Grupos
      </button>
    </div>

    <!--
    <div class="list-group-item list-group-item-action bg-light ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" (click)="isCollapsedAmonestaciones = !isCollapsedAmonestaciones"
        [attr.aria-expanded]="!isCollapsedAmonestaciones" aria-controls="collapseAmonestaciones">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-id-card" aria-hidden="true"></i></span> Amonestaciones
      </button>
      <div id="collapseAmonestaciones" class="" [ngbCollapse]="!isCollapsedAmonestaciones">
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/amonestaciones/profesor/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-id-card" aria-hidden="true"></i></span> De profesor
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/amonestaciones/tutoria/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-id-card" aria-hidden="true"></i></span> De tutoría
          </button>
        </div>
      </div>
    </div>
  -->

    <!-- Sanciones
    <div class="list-group-item list-group-item-action bg-light ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" (click)="isCollapsedSanciones = !isCollapsedSanciones"
        [attr.aria-expanded]="!isCollapsedSanciones" aria-controls="collapseExample">
        <span class="text-start" style="float:left; width:2rem;">
          <i class="fa fa-handshake-o" aria-hidden="true"></i></span> Sanciones
      </button>
      <div id="collapseExample" class="" [ngbCollapse]="!isCollapsedSanciones">
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/amonestaciones/">
            <span class="text-start" style="float:left; width:2rem;">
              <i class="fa fa-search" aria-hidden="true"></i></span> Buscar sanción
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/amonestaciones/">
              <span class="text-start" style="float:left; width:2rem;">
                <i class="fa fa-plus" aria-hidden="true"></i></span> Nueva sanción
          </button>
        </div>
      </div>
    </div>
  -->

    <!-- Convivencia -->
    <div *ngIf="authService.isUserAdmin()"
      class="list-group-item list-group-item-action bg-light border-bottom ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0"
        (click)="isCollapsedConvi = !isCollapsedConvi" [attr.aria-expanded]="!isCollapsedConvi"
        aria-controls="collapseConvi">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-id-card" aria-hidden="true"></i></span> Convivencia
      </button>
      <div id="collapseConvi" class="" [ngbCollapse]="!isCollapsedConvi">

        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/amonestaciones/">
            Amonestaciones
<!-- Con icono:
              <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-id-card" aria-hidden="true"></i></span> Amonestaciones
-->
            </button>
        </div>

        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/actuaciones/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-id-card" aria-hidden="true"></i></span> Actuaciones
          </button>
        </div>

      </div>
    </div>

    <!--
    <div class="list-group-item list-group-item-action bg-light">
    
    </div>
-->

    <!-- Administración -->
    <div *ngIf="authService.isUserAdmin()" class="list-group-item list-group-item-action bg-light border-bottom ps-2 pe-0">
      <button type="button" class="btn btn-light fw-bold border-0" (click)="isCollapsedAjustes = !isCollapsedAjustes"
        [attr.aria-expanded]="!isCollapsedAjustes" aria-controls="collapseExample">
        <span class="text-center me-2" style="float:left; width:2rem;">
          <i class="fa fa-cogs" aria-hidden="true"></i></span> Administración
      </button>
      <div id="collapseExample" class="" [ngbCollapse]="!isCollapsedAjustes">
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/ajustes/importar-datos">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-database" aria-hidden="true"></i></span> Importar datos
          </button>
        </div>

        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/usuarios/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-graduation-cap" aria-hidden="true"></i></span> Profesores
          </button>
        </div>

<!--
        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/alumnos/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-user" aria-hidden="true"></i></span> Alumnos
          </button>
        </div>

        <div>
          <button type="button" class="btn btn-light border-0" routerLink="/grupos/">
            <span class="text-center me-2" style="float:left; width:2rem;">
              <i class="fa fa-users" aria-hidden="true"></i></span> Grupos
          </button>
        </div>
-->
      </div>
    </div>




  </div>

</div>