import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public title = 'Gestión de Centro';
  isSideMenuCollapsed: boolean = false;
  
  constructor(public authService: AuthenticationService) { }
  
  ngOnInit() {
  }

  changeSideMenuCollapsed() {
    //console.log("app.component: changeSideMenuCollapsed()")
    this.isSideMenuCollapsed = !this.isSideMenuCollapsed;
  }
  
}

