import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.css']
})
export class NavbarTopComponent implements OnInit {
  @Output() toogleMenu = new EventEmitter();

  username: string;
  fullname: string;
  email: string;

  // Controla el menú principal (colapsado o expandido)
  collapsedMainMenu = true;

  constructor(private router: Router, public authService: AuthenticationService) { }

  ngOnInit() {
    this.username = this.authService.getUsername();
    this.fullname = this.authService.getUserFullName();
    this.email = this.authService.getUserEmail();
  }

  updatedToogleMenu() {
    console.log("Navbar-top.Component: toogleButton pressed.")
    this.toogleMenu.emit();
  }

  logout() {
    this.authService.logout().subscribe(
      resp => {
        console.log('Navbar-top.Component. Logout OK. ' + resp);
        this.router.navigate(['/login']);
      },
      error => {
        console.log('Navbar-top.Component. Logout Error: ' + error);
        this.router.navigate(['/login']);
      }
    );
  }

}
