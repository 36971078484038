import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'Gestión de Centro';
  isSideMenuCollapsed: boolean = false;

  constructor(public authService: AuthenticationService) { }

  changeSideMenuCollapsed() {
    //console.log("app.component: changeSideMenuCollapsed()")
    this.isSideMenuCollapsed = !this.isSideMenuCollapsed;
  }
}
